import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { SectionBodyProps, SectionProps } from "./types";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import Accordion from "@willsComponents/Accordion";
import {
  Answer,
  AnswersContainer,
  HeadingContainer,
  Question,
  Row,
} from "./styled";
import { getAssetTotal } from "@Zakat/helpers/zakat/calculatePortfolioValue";
import { Text } from "@ifgengineering/component-library";
import { nestedArray, renderField } from "@willsComponents/Summary/helpers";
import { CurrencyContext } from "@components/CurrencyContext";

const SectionBody = ({
  data,
  schema,
  asset,
  item,
}: SectionBodyProps): JSX.Element => {
  const assetData = { ...data };
  if (asset === "cash" && item === "cashISA")
    assetData.totalCashValue = assetData.cashISA;
  if (asset === "cash" && item === "cashInHand")
    assetData.totalCashValue = assetData.cashInHand;
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const { zakat: zakatableAsset } = getAssetTotal(asset, assetData);
  const { formatCurrency } = useContext(CurrencyContext);
  return (
    <Row>
      <Question>{schema[item]}</Question>
      <AnswersContainer>
        <Answer>
          {Array.isArray(data[item])
            ? nestedArray(data, item)
            : renderField(data, item)}
        </Answer>
        <Answer data-testid="zakat-amount-due">
          {isMobile && (
            <Text color="SLATE700" type="P16">
              {"Zakatable amount: "}
            </Text>
          )}
          {item === "debt" && zakatableAsset > 0
            ? `-${formatCurrency(zakatableAsset)}`
            : formatCurrency(zakatableAsset)}
        </Answer>
      </AnswersContainer>
    </Row>
  );
};

export const Section: (props: SectionProps) => JSX.Element | null = ({
  schema,
  data,
}) => {
  if (!data) return null;
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const content = () => (
    <div>
      {!isMobile && (
        <HeadingContainer>
          <Question>
            <Text color={"SLATE700"} type="H16">
              ASSET NAME
            </Text>
          </Question>
          <AnswersContainer>
            <Text color={"SLATE700"} type="H16">
              ASSET TOTAL
            </Text>
            <Text color={"SLATE700"} type="H16">
              ZAKATABLE AMOUNT
            </Text>
          </AnswersContainer>
        </HeadingContainer>
      )}
      {Object.keys(schema).map((item, i) => {
        if (typeof schema[item] === "string") {
          return (
            <SectionBody
              asset={item}
              schema={schema}
              item={item}
              data={data}
              key={i}
            />
          );
        }

        return Object.keys(schema[item]).map((nestedItem) => {
          if (nestedItem && data[item] && data[item][nestedItem]) {
            if (
              Array.isArray(data[item][nestedItem]) &&
              !data[item][nestedItem].length
            ) {
              return null;
            }

            return (
              <SectionBody
                asset={item}
                schema={schema[item]}
                item={nestedItem}
                data={data[item]}
                key={i + nestedItem}
              />
            );
          }
        });
      })}
    </div>
  );
  if (isMobile)
    return <Accordion title="Full Breakdown">{content()}</Accordion>;
  return content();
};
