import styled from "styled-components";
import { theme as legacyTheme } from "../../../../styles/legacy-theme";
import { theme } from "../../../../styles/theme";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 14px 0 10px 0;
  border-bottom: 2px solid ${legacyTheme.colors.Grey01};
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const AnswersContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: left;
  }
`;

export const WidthWrapper = styled.div`
  max-width: 850px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  text-align: left;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Question = styled.div`
  text-align: left;
  font-size: 16px;
  width: 250px;
  min-width: 250px;
`;

export const Answer = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: left;
    padding-top: 10px;
  }
`;

export const Heading = styled.h4`
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
`;

export const Title = styled.h4`
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${legacyTheme.colors.Primary03};
`;

export const Text = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: ${legacyTheme.colors.Primary03};
`;

export const Link = styled.p`
  font-size: 14px;
  margin-left: 8px;
  color: ${legacyTheme.colors.Primary01};
`;

export const ButtonWrapper = styled.div`
  width: 327px;
  margin-bottom: 30px;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ShowMoreText = styled.div`
  width: 288px;
  font-size: 14px;
  white-space: break-spaces;
  color: ${theme.colors.SLATE700};
`;
