export const assetSchema = {
  cash: {
    cashISA: "Cash:",
    cashInHand: "Cash in hand:",
  },
  bankAccounts: {
    bankAccountList: "Bank accounts:",
  },
  stockAccounts: {
    stockAccountList: "Stock accounts:",
  },
  cryptoAccounts: {
    cryptoAccountList: "Crypto accounts:",
  },
  commodities: {
    commoditiesList: "Commodities:",
  },
  childTrustFunds: {
    childTrustFundList: "Child trust funds:",
  },
  funds: {
    fundsList: "Funds:",
  },
  stocks: {
    shareList: "Shares list:",
  },
  crypto: {
    cryptoList: "Cryptos:",
  },
  pensions: {
    pensionList: "Pensions:",
  },
  properties: {
    propertyList: "Properties:",
  },
  businessAssets: {
    businessList: "Business assets:",
  },
  startups: {
    startupInvestments: "Startup investments:",
  },
  agricultureAssets: {
    agricultureList: "Agricultural assets:",
  },
  alternativeInvestments: {
    alternativeAssetList: "Alternative investments:",
  },
  owedAssets: {
    owedAssetList: "Assets you are owed:",
  },
  debts: {
    debt: "Debts:",
  },
};

export const assetSchemaV2 = {
  cashISA: "Cash:",
  cashInHand: "Cash in hand:",
  bankAccounts: "Bank accounts:",
  stockAccounts: "Stock accounts:",
  commodities: "Commodities:",
  childTrustFunds: "Child trust funds:",
  funds: "Funds:",
  stocks: "Shares list:",
  cryptos: "Cryptos:",
  pensions: "Pensions:",
  properties: "Properties:",
  businessAssets: "Business assets:",
  startups: "Startup investments:",
  agricultureAssets: "Agricultural assets:",
  alternativeInvestments: "Alternative investments:",
  owedAssets: "Assets you are owed:",
  debts: "Debts:",
};
